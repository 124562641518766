import React, { useState, useEffect, useRef, useMemo } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Modal, Col, Row, Badge } from 'react-bootstrap'
import DataTable from '../DataTable'
import { useGetTableData } from '../../hooks'
import useBottomScrollListener from '../../hooks/useBottomScrollListener'
import FormField from '../FormField'
import FormCheckbox from '../FormCheckbox'
import {
  addDispatchBooking,
  addManualDispatch,
  updateServiceNotes,
  logger,
} from '../../actions'
import { Field, Form, Formik } from 'formik'
import moment from 'moment'
import {
  getFormattedPrice,
  getLabelFromValue,
  getOptionFromValue,
  numberWithCommas,
} from 'helpers'
import {
  defaultCurrencies,
  defaultTwentyFourHoursService,
} from '../../constants'
import TooltipOnHover from '../TooltipOnHover'
import FormSelect from 'components/FormSelect'
import { Toggle } from 'components/toggle'
import SubmitBtn from 'components/SubmitBtn'

let order = [
  'GOLD',
  'SILVER',
  'BRONZE',
  'BACKUP',
  'NEW',
  'BLACKLISTED',
  'INAPT',
]

const DispatchPartnerModal = ({
  show,
  onHide,
  currentItem,
  addDispatchBooking,
  serviceNotes = '',
  logger,
  localCurrency,
  dispatchedSuppliers,
  supplierCurrency,
  updateBooking,
  buyingPrice,
  originalPrice,
  updateServiceNotes,
  isApiBooking = false,
  bookingId,
}) => {
  const scrollRef = useRef(null)
  const filterOperators = useMemo(
    () => ({
      verified: 'eq',
      selectRental: 'eq',
      selectChauffeur: 'eq',
      active: 'eq',
      twentyFourHourService: 'eq',
      isLocalSupplier: 'eq',
    }),
    []
  )
  const initialFilters = {
    name: '',
    verified: 'true',
    isLocalSupplier: 'false',
    vehicleName: currentItem.vehicleName,
  }
  const [invites, setInvites] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [noSupplierSelected, setNoSupplierSelected] = useState(false)
  const [newLocalList, setNewLocalList] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [isEditingNotes, setIsEditingNotes] = useState(
    serviceNotes ? false : true
  )
  const [updatedRate, setUpdatedRate] = useState(0)
  const [marginPercentage, setMarginPercentage] = useState(null)
  const [marginAmount, setMarginAmount] = useState(null)
  const [showDispatchCancelModal, setshowDispatchCancelModal] = useState(false)
  const [isTwentyFourService, setIsTwentyFourService] = useState(false)
  // console.log('currentItem', currentItem)

  let setUrl =
    currentItem.type === 'chauffeur_services'
      ? `company/partner/${currentItem.pickUpLocation.lng}/${
          currentItem.pickUpLocation.lat
        }/${null}/${null}`
      : `company/partner/${currentItem.pickUpLocation.lng}/${currentItem.pickUpLocation.lat}/${currentItem.dropOffLocation.lng}/${currentItem.dropOffLocation.lat}`

  const {
    data: { list: localList },
    getNextPage,
    filters,
    reloadList,
    onFiltersChange,
    setFilters,
    isLoading: isLocalLoading,
  } = useGetTableData(setUrl, initialFilters, { filterOperators })

  useEffect(() => {
    if (currentItem) {
      if (currentItem.type === 'car_rentals') {
        setFilters({ ...filters, selectRental: true, active: true })
      } else {
        setFilters({ ...filters, selectChauffeur: true, active: true })
      }
    }

    setUpdatedRate(parseFloat(buyingPrice))
  }, [currentItem, buyingPrice])

  useEffect(() => {
    if (localList && localList.length > 0) {
      let mutantList = localList.sort(({ kpiInfo: a }, { kpiInfo: b }) => {
        if (!a.score) a.score = 0
        if (!b.score) b.score = 0
        return b.score - a.score
      })

      if (dispatchedSuppliers && dispatchedSuppliers.length > 0) {
        mutantList.forEach(listItems => {
          dispatchedSuppliers.forEach(item => {
            if (
              listItems.id === item.supplierId &&
              (item.status === 'dispatched' || item.status === 'opened')
            ) {
              listItems.dispatched = true
              if (!invites.some(item => item.supplierId === listItems.id)) {
                const emails = listItems.contactDetails.map(
                  item => item.contactsEmail
                )
                setInvites(prev => [
                  ...prev,
                  {
                    supplierId: listItems.id,
                    name: listItems.name,
                    emails: emails,
                    status: 'dispatched',
                    supplierCurrency: listItems.currency,
                    bookingCurrency: currentItem.bookingTimeRateInfo.currency,
                    buyingPrice: parseFloat(updatedRate),
                  },
                ])
              }
            }
          })
        })
      } else if (!dispatchedSuppliers.length && invites.length) {
        mutantList.forEach(item => {
          delete item.dispatched
        })
      }

      if (mutantList && mutantList.length) {
        mutantList.sort((a, b) => {
          // Compare dispatched status first (true values first)
          const aDispatched = a.dispatched ? 1 : 0
          const bDispatched = b.dispatched ? 1 : 0
          if (aDispatched !== bDispatched) {
            return bDispatched - aDispatched
          }
          // Then compare based on the 'order' array
          return (
            order.indexOf(a.status.value.toUpperCase()) -
            order.indexOf(b.status.value.toUpperCase())
          )
        })
      }

      setNewLocalList(mutantList)
    } else if (localList?.length === 0 && newLocalList.length !== 0) {
      setNewLocalList([])
    }
  }, [newLocalList, localList, dispatchedSuppliers.length])

  useEffect(() => {
    let rate = updatedRate ? updatedRate : buyingPrice
    setMarginPercentage(
      +(((originalPrice - rate) / originalPrice) * 100).toFixed(2)
    )
    setMarginAmount((originalPrice - rate).toFixed(2))
  }, [updatedRate, originalPrice])

  const onSubmit = async values => {
    try {
      setSubmitting(true)
      let serviceNotes = document.getElementById('serviceNotes')
        ? document.getElementById('serviceNotes').innerText
        : ''

      // let updatedArray = invites.map(item => {
      //   return {
      //     ...item,
      //     buyingPrice: updatedRate,
      //   }
      // })

      if (invites.length) {
        if (selectedTab === 0) {
          await addDispatchBooking(currentItem._id, {
            invites: invites,
            price: parseFloat(updatedRate),
            serviceNotes: serviceNotes,
            localRate: parseFloat(updatedRate),
            localCurrency: localCurrency,
            isOperativeSupplier: true,
          })
        }

        if (currentItem) {
          if (currentItem.type === 'car_rentals') {
            setFilters({
              name: '',
              verified: 'true',
              selectRental: true,
              active: true,
            })
          } else {
            setFilters({
              name: '',
              verified: 'true',
              selectChauffeur: true,
              active: true,
            })
          }
        }

        setInvites([])
        onHide()
        reloadList()
      } else {
        setNoSupplierSelected(true)
      }

      await updateBooking()
    } catch (err) {
      logger({ fileName: 'DispatchBookingModal', error: err })
      console.log(err)
    } finally {
      setSubmitting(false)
    }
  }

  const addToDispatch = async (id, name, emails, currency) => {
    if (selectAll) {
      setSelectAll(false)
    }
    let isManual = selectedTab === 2 && invites.length === 1
    if (invites.some(item => item.supplierId === id)) {
      const inviteIndex = invites.findIndex(item => item.supplierId === id)
      invites.splice(inviteIndex, 1)
      setInvites([...invites])
    } else {
      if (!isManual) {
        setNoSupplierSelected(false)
        setInvites([
          ...invites,
          {
            supplierId: id,
            name: name,
            emails: emails,
            status: 'dispatched',
            supplierCurrency: currency,
            bookingCurrency: currentItem.bookingTimeRateInfo.currency,
            buyingPrice: parseFloat(updatedRate),
          },
        ])
      }
    }
  }

  const toggleAllSuppliers = async () => {
    if (!selectAll) {
      setNoSupplierSelected(false)
      setSelectAll(true)
      let inviteList = []
      localList.map(item =>
        inviteList.push({
          supplierId: item.id,
          name: item.name,
          emails: item.contactDetails
            .filter(contact => contact.excludeComms !== true)
            .map(contact => contact.contactsEmail),
          status: 'dispatched',
          supplierCurrency: item.currency,
          bookingCurrency: currentItem.bookingTimeRateInfo.currency,
          buyingPrice: parseFloat(updatedRate),
        })
      )
      setInvites(inviteList)
    } else {
      setSelectAll(false)
      setInvites([])
    }
  }

  const columns = [
    {
      selector: 'name',
      renderCol: ({ place, name, status, kpiInfo }) => {
        return (
          <>
            <div className='dispatch-container'>
              <div className={'dispatch-info d-flex'}>
                <span className='d-flex align-items-center'>
                  {status && (
                    <Badge
                      href='#'
                      variant={`${status.value}-admin badge-outlined py-1 p1-2 mb-0 mr-2`}
                      style={{ width: '5rem' }}
                    >
                      {status.label}
                    </Badge>
                  )}
                  <p
                    style={{ marginTop: '0.1rem' }}
                    className='header mb-0 mr-1'
                  >
                    {name}
                  </p>{' '}
                  <p style={{ marginTop: '0.1rem' }} className='header mb-0'>
                    {'- ' + place.city + ', ' + place.state}
                  </p>
                  {kpiInfo && kpiInfo.totalAcceptedBookings ? (
                    //prettier-ignore
                    <TooltipOnHover
                    delay={200}
                    placement='right-start'
                    isMobileBrowser={false}
                    onMouseEnter={() => {}}
                    component={
                      <>
                        <div>
                          <div className='kpi-dispatch-end'>
                            <span>
                              {kpiInfo.totalAssignedBookings
                                ? `${kpiInfo.totalAssignedBookings} booking${
                                    kpiInfo.totalAssignedBookings === 1
                                      ? ''
                                      : 's'
                                  }`
                                : ''}
                            </span>
                          </div>
                          {kpiInfo.totalAcceptedBookings ? (
                            <>
                          <div className='kpi-dispatch-end'>
                            <span>
                              {kpiInfo.acceptanceRate && (
                                <span>{`${kpiInfo.acceptanceRate}% acceptance rate`}</span>
                              )}
                            </span>
                          </div>
                              <div className='kpi-dispatch-end'>
                                <span>{`${kpiInfo.marginAverage}% average net margin`}</span>
                              </div>
                                <div className='kpi-bookings'>
                                  <span>
                                    {`${
                                      kpiInfo.escalations
                                    } escalation${
                                      kpiInfo.escalations !== 1
                                        ? 's'
                                        : ''
                                    }`}
                                  </span>
                                </div>
                              <div className='kpi-dispatch-end'>
                                <span>
                                  {kpiInfo.averageAcceptanceTime} avg.
                                  acceptance time
                                </span>
                              </div>
                              <div className='kpi-dispatch-end'>
                                <span>{`Last booking accepted ${moment(
                                  kpiInfo.dateOfRecentDispatch
                                ).fromNow()}`}</span>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    }
                  >
                    <img
                      className={'new-info-icon'}
                      style={{ marginLeft: '0.5rem', marginTop: '0.15rem' }}
                      src='/images/icons/new-info-icon.png'
                      alt=''
                    />
                  </TooltipOnHover>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
            </div>
          </>
        )
      },
    },
    {
      renderCol: ({ id, name, contactDetails, currency, dispatched }) => {
        let emails = ''
        if (contactDetails)
          emails = contactDetails
            .filter(contact => contact.excludeComms !== true)
            .map(contact => contact.contactsEmail)

        return (
          <div
            style={{ marginRight: '20%', marginBottom: '0.3rem' }}
            className={'share-button'}
          >
            <Field
              component={FormCheckbox}
              name={`dispatch[${id}]`}
              checked={invites.some(invite => invite.supplierId === id)}
              label=''
              className='vehicle-checkbox is-editable-checkbox mt-0'
              onClick={() => addToDispatch(id, name, emails, currency)}
            />
          </div>
        )
      },
    },
  ]
  // console.log('currentItem', currentItem)
  // console.log('newLocalList', newLocalList)
  useBottomScrollListener(getNextPage, scrollRef)

  const onClose = () => {
    if (currentItem) {
      if (currentItem.type === 'car_rentals') {
        setFilters({
          name: '',
          verified: 'true',
          selectRental: true,
          active: true,
        })
      } else {
        setFilters({
          name: '',
          verified: 'true',
          selectChauffeur: true,
          active: true,
        })
      }
    }

    setInvites([])
    onHide()
    setNoSupplierSelected(false)
  }

  const toggleState = state => {
    if (state) {
      onFiltersChange('twentyFourHourService')({
        target: { value: state },
      })
      setIsTwentyFourService(state)
    } else {
      onFiltersChange('twentyFourHourService')({
        target: { value: '' },
      })
      setIsTwentyFourService(state)
    }
  }

  //this function is a failure just like ... my code
  const onSendChanges = async values => {
    await updateServiceNotes(
      isApiBooking
        ? { services_location_notes: values }
        : { serviceNotes: values },
      bookingId
    )
    setIsEditingNotes(false)
    await updateBooking()
  }

  return (
    <Modal
      size='lg'
      show={show}
      onHide={onClose}
      className='dispatch-booking-modal modal-90w'
      dialogClassName='modal-90w '
      aria-labelledby='contained-modal-title-vcenter'
      centered
      // onSubmit={onSubmit}
      id={'modal'}
    >
      <Modal.Header>
        <Modal.Title id='example-modal-sizes-title-lg' className='modal-title'>
          <>
            <Row className='d-flex align-items-center'>
              <Col>
                <div style={{ marginTop: '2rem' }}>
                  <h1 className='dispatch-title mb-0 pb-0'>
                    Outsource booking #
                    {`${currentItem ? currentItem.number : ''}`}
                  </h1>
                  <Button variant='link' className='' onClick={onClose}>
                    <img src='/images/icons/close-icon-black.png' alt='' />
                  </Button>
                </div>
              </Col>
            </Row>
            <p className='dispatch-subtitle mt-0 pt-0'>
              Select partners and click on "Confirm".
            </p>
          </>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Col className='pl-5 mt-5 d-flex align-items-center'>
            <Field
              component={FormField}
              name='dispatchPrice'
              controlId={'dispatchPrice'}
              className='payment-field-dispatch hide-number-spinners'
              label='Buying price'
              type='number'
              value={updatedRate}
              groupClassName='mb-1'
              handleChange={evt => {
                if (!evt) {
                  setUpdatedRate(null)
                } else {
                  setUpdatedRate(parseFloat(evt))
                }
              }}
            />
            <span className='ml-2 mt-3'>
              {`${getLabelFromValue(defaultCurrencies, supplierCurrency)}`}
            </span>
          </Col>
          <Col className='pl-5 mb-4 d-flex align-items-center'>
            <span
              className={`${
                marginPercentage > 20 || marginPercentage === 20
                  ? 'most-margin-label'
                  : marginPercentage > 0 && marginPercentage <= 10
                  ? 'less-margin-label'
                  : 'least-margin-label'
              }`}
            >
              {`INCL. ${marginPercentage}% MARGIN (${numberWithCommas(
                getFormattedPrice(parseFloat(marginAmount))
              )} ${getLabelFromValue(defaultCurrencies, supplierCurrency)})`}
            </span>
          </Col>

          <Col className='px-5'>
            <div className='d-flex justify-content-between'>
              <label> Service notes </label>

              {!isEditingNotes ? (
                <Button
                  variant='link'
                  onClick={() => {
                    setIsEditingNotes(true)
                  }}
                  className='inline-edit-btn text-brown'
                >
                  Edit
                </Button>
              ) : (
                <></>
              )}
            </div>
            {!isEditingNotes ? (
              <>
                <pre
                  className={'form-group notes-expandable-dispatch-accepted'}
                  id={'serviceNotes'}
                  style={{ whiteSpace: 'break-spaces' }}
                >
                  {serviceNotes}
                </pre>
              </>
            ) : (
              <>
                <span
                  id={'serviceNotes'}
                  className={'form-group notes-expandable-dispatch'}
                  style={{ minHeight: '80px' }}
                  as={'textarea'}
                  contentEditable={'plaintext-only'}
                  suppressContentEditableWarning={true}
                >
                  {serviceNotes}
                </span>
              </>
            )}
          </Col>
          {isEditingNotes && serviceNotes !== '' ? (
            <Col className='px-5'>
              <div className='d-flex justify-content-end'>
                <Button
                  variant='link'
                  onClick={evt => {
                    setIsEditingNotes(false)
                  }}
                  className='cancel-btn mr-4 text-brown'
                >
                  Cancel
                </Button>
                <div
                  onClick={() =>
                    onSendChanges(
                      document.getElementById('serviceNotes') &&
                        document.getElementById('serviceNotes').innerText
                        ? document.getElementById('serviceNotes').innerText
                        : serviceNotes
                    )
                  }
                >
                  <SubmitBtn
                    style={{
                      height: '35px',
                      paddingLeft: '30px',
                      paddingRight: '30px',
                      marginTop: '0px',
                      fontSize: '14px',
                    }}
                    title={'Save'}
                  />
                </div>
              </div>
            </Col>
          ) : (
            <></>
          )}
          <div className={'partner-modal-tab  mt-2 px-5'}>
            <div className={'d-flex justify-content-start'}></div>
          </div>
          {selectedTab === 0 ? (
            <div>
              {/* Partners of pick-up location */}
              {localList && (
                <>
                  <div className='d-flex align-items-center justify-content-between mb-3 mt-0 px-5'>
                    <h4 className='sub-header-dispatch mb-0'>
                      {` Select partners (${invites.length}/${localList.length})`}
                    </h4>
                  </div>
                  <Row className='mx-0'>
                    <Col md='3' className='pl-5 pr-0'>
                      <FormField
                        field={{
                          name: 'name',
                          placeholder: 'Name...',
                          value: filters['name'],
                          onChange: onFiltersChange('name'),
                        }}
                      />
                    </Col>
                    <Col md='3' className='pr-0'>
                      <FormField
                        field={{
                          name: 'name',
                          placeholder: 'Vehicle...',
                          value: filters['vehicles.make.label'],
                          onChange: onFiltersChange('vehicles.make.label'),
                        }}
                      />
                    </Col>
                    <Col
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1rem',
                        marginLeft: '1rem',
                      }}
                      md='3'
                      className='pr-0 pl-0'
                    >
                      <Toggle
                        label='24/7'
                        toggled={isTwentyFourService}
                        className={isTwentyFourService ? 'active ml-2' : 'ml-2'}
                        onClick={toggleState}
                        isPartner
                      />
                    </Col>
                    <Col className='check-box-dispatch'>
                      <Field
                        component={FormCheckbox}
                        name={'select all'}
                        label=''
                        className='vehicle-checkbox is-editable-checkbox mt-3'
                        checked={selectAll}
                        onClick={() => toggleAllSuppliers()}
                        onSubmit={onSubmit}
                        style={{ paddingRight: '2%' }}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <div className={'px-5 shareDispatchList'} ref={scrollRef}>
                <DataTable
                  list={newLocalList}
                  isLoading={isLocalLoading}
                  columns={columns}
                  showNoData={true}
                  showHead={false}
                  className='light-theme-table-admin-bookings'
                />
              </div>
              {noSupplierSelected && (
                <p className='error-msg-partner'>
                  Please select at least one partner.
                </p>
              )}
              {currentItem && currentItem.driver && (
                <p className='error-msg-partner'>
                  This booking is already assigned to a chauffeur. Clicking
                  "Outsource" will remove the chauffeur assigned.
                </p>
              )}
              <div className={'share-button dispatch-modal-button'}>
                <>
                  <Button
                    className='select-btn h-40 share mr-5'
                    variant='brown'
                    onClick={() => onSubmit()}
                    disabled={isSubmitting}
                  >
                    Confirm
                  </Button>
                </>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
const mapStateToProps = ({ auth } = {}) => ({})

export default withRouter(
  connect(mapStateToProps, {
    addDispatchBooking,
    addManualDispatch,
    logger,
    updateServiceNotes,
  })(DispatchPartnerModal)
)
